import React, { useState } from 'react'
import { Collapse } from '@material-ui/core'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { alpha, colors, s, globals } from '../../style'
import Arrow from '../../assets/SVGarrow'
import LOGO from '../../assets/ReklamaDlaBiznesu.svg'

const theme = 'light'

const MenuNew = ({ white, language, isScrollTop }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const data = useStaticQuery(graphql`
    {
      menus: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/menus/" } }
      ) {
        edges {
          node {
            frontmatter {
              language
              links {
                name
                link
                links {
                  name
                  link
                  links {
                    name
                    link
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const menu = data.menus.edges
    .filter((e) => e.node.frontmatter?.links?.length > 0)
    .filter((e) => e.node.frontmatter?.language === language)
    .map((e) =>
      e.node.frontmatter.links.map(({ name, link, links }) => ({
        language: e.node.frontmatter.language,
        name,
        link,
        links,
      }))
    )
    .reduce((a, b) => a.concat(b), [])

  return (
    <>
      <div
        css={[
          sBackgroundMenu,
          isScrollTop && {
            opacity: 0,
          },
        ]}
      />
      <nav css={[sMenu, white && { color: colors[theme].text }]}>
        <Link
          to="/"
          css={[sLogo, white && menuOpen && { color: 'white' }]}
          name="Reklama dla Biznesu">
          <img src={LOGO} alt="Reklama Dla Biznesu" />
        </Link>

        {/* MOBILE */}
        <MenuMobile data={menu} menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        {/* DESKTOP */}
        <ul css={[sUL, sDesktop]}>
          {menu?.map((menuItem, id) => (
            <LinkMain key={id} data={menuItem} id={id} items={menu.length} />
          ))}
        </ul>
        {/* <div css={sDesktop} /> */}
        <button
          name="menu"
          title="Menu"
          aria-label="Menu"
          css={[sMobile, sMenuButton, menuOpen && sMenuButtonActive]}
          onClick={() => setMenuOpen(!menuOpen)}>
          <div className="top"></div>
          <div className="mid"></div>
          <div className="bot"></div>
        </button>
      </nav>
      <div css={sBackground(white)} />
    </>
  )
}

const MenuMobile = ({ data, menuOpen, setMenuOpen }) => {
  return (
    <ul
      css={[
        sMenuMobile,

        menuOpen && { pointerEvents: 'auto', opacity: 1 },
        {
          [s.md]: {
            display: 'grid',
            gridAutoRows: 'min-content',
            gridTemplateColumns: '1fr 1fr',
          },
        },
        sMobile,
      ]}>
      {data.map((menuItem, id) => (
        <LinkMobile
          key={id}
          data={menuItem}
          nested={0}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
        />
      ))}
    </ul>
  )
}

const LinkMobile = ({ data, nested, menuOpen, setMenuOpen }) => {
  const [open, setOpen] = useState(false)
  const { name, link, links } = data

  const isNoLinksChild = nested > 1 && !(links?.length > 0)
  return (
    <li
      css={[
        {
          listStyle: 'none',
          margin: '1rem 0',
          [s.sm]: { margin: 0, padding: '1rem 2rem 0 0', flexShrink: 0 },
        },
        nested === 0 && { [s.sm]: { padding: '2rem' } },
      ]}>
      <div
        css={[
          {
            willChange: 'background-color',
            transition: 'background-color 0.2s ease-out',
            display: 'flex',
            alignItems: 'center',
            // padding: '1rem 0 1rem 0rem',

            borderRadius: '1rem',
            marginLeft: 1.5 * nested + 'rem',
            [s.ss]: { marginLeft: 1 * nested + 'rem' },
          },
          open && { backgroundColor: colors[theme].text },
        ]}>
        {links ? (
          <div
            aria-label="expand"
            onClick={() => setOpen(!open)}
            onKeyPress={() => setOpen(!open)}
            tabIndex={0}
            role="button"
            css={[
              sArrowMobile,
              open
                ? { svg: { transform: 'rotate(-90deg)' }, opacity: 1 }
                : { svg: { transform: 'rotate(90deg)' }, opacity: 0.3 },
            ]}>
            <Arrow />
          </div>
        ) : (
          <div
            css={[
              sArrowMobile,
              isNoLinksChild && {
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
              },
            ]}>
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="10" r="2" fill="currentColor" />
            </svg>
          </div>
        )}
        <Link
          to={link}
          onClick={() => setMenuOpen(!menuOpen)}
          css={[
            sSublinkMobile,
            nested === 0 && sLinkMobile,
            { padding: '1rem 1rem 1rem 0rem' },
            isNoLinksChild && {
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
              [s.sm]: {
                fontSize: '0.875rem',
              },
            },
            links?.length > 0 &&
              !open &&
              nested > 0 &&
              !isNoLinksChild && { opacity: 0.6 },
          ]}>
          {name}
        </Link>
      </div>
      {links && (
        <Collapse in={open}>
          <ul
          // css={{
          //   [s.sm]: { ...sGrid(links.length >= 30 ? 29 : links.length) },
          // }}
          >
            {links.map((menuItem, id) => (
              <LinkMobile key={id} data={menuItem} nested={nested + 1} />
            ))}
          </ul>
        </Collapse>
      )}
    </li>
  )
}

const sArrowMobile = {
  border: 'none',
  appearance: 'none',
  background: 'transparent',
  padding: '1rem 1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'currentColor',
  svg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1.25rem',
    height: '1.25rem',
    transition: 'transform 0.2s ease-out, opacity 0.2s ease-out',
  },
}

const sSublinkMobile = {
  fontSize: '0.75rem',
  width: '100%',
  cursor: 'pointer',
  fontWeight: 700,
  letterSpacing: '0.05em',
  transition: 'opacity 0.2s ease-out',
  willChange: 'opacity',
  [s.sm]: {
    fontSize: '1rem',
  },
}

const sLinkMobile = {
  fontSize: '1rem',
  [s.sm]: {
    fontSize: '1.5rem',
  },
}

const sMenuMobile = {
  pointerEvents: 'none',
  opacity: 0,
  transition: 'opacity 0.2s ease-out',
  willChange: 'opacity',
  position: 'absolute',
  top: '-1rem',
  marginTop: 0,
  left: 0,
  zIndex: 700,
  width: '100vw',
  height: '100vh',
  overflowX: 'hidden',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  background: `linear-gradient(to bottom, ${colors[theme].text}, ${colors[theme].text})`,
  padding: '6rem 2rem 4rem',
  color: colors[theme].container,
  '::-webkit-scrollbar': {
    width: 0,
  },
  [s.sm]: {
    top: '-2rem',
    padding: '8rem 2rem 4rem',
  },
  [s.md]: {
    padding: '8rem 2rem 4rem 2rem',
  },
}

const sMenuButton = {
  position: 'relative',
  top: 0,
  left: 0,
  zIndex: 1000,
  backgroundColor: 'transparent',
  appearance: 'none',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  transition: 'all 0.2s ease-out',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  color: 'currentColor',
  borderRadius: 24,
  '.top, .mid, .bot': {
    backgroundColor: 'currentColor',
    height: 3,
    borderRadius: 4,
    margin: '2px 0',
    transition: 'inherit',
    width: 24,
    [s.xs]: { width: 20 },
    [s.sm]: { width: 24 },
  },
}

const sMenuButtonActive = {
  color: 'white',
  '.top': {
    transform: 'translate3d(0px, 7px, 0) rotate(-45deg)',
  },
  '.mid': {
    transform: 'scaleX(0.7)',
    opacity: 0,
  },
  '.bot': {
    transform: 'translate3d(0px, -7px, 0) rotate(45deg)',
  },
}

const LinkMain = ({ data, items, id }) => {
  const { link, name, links } = data

  return (
    <li
      css={[
        sLinkMain,
        !links?.length > 0 && { '.link': { borderRadius: '1rem' } },
      ]}>
      <div css={[sFlexRow, { position: 'relative', top: 0, left: 0 }]}>
        <Link to={link} className="link">
          {name}
        </Link>

        {links?.length > 0 && (
          <div css={sArrowRotated}>
            <Arrow />
          </div>
        )}
      </div>

      {links?.length > 0 && (
        <ul
          css={[
            sSublinksContainer,
            links.length > 10 ? sGrid(links.length) : sFlex,
          ]}
          className="sublinks">
          {links.map((linkItem, id) => (
            <LinkChild key={id} data={linkItem} items={links.length} id={id} />
          ))}
        </ul>
      )}
    </li>
  )
}

const LinkChild = ({ data, nested: nestedProp, items, id }) => {
  const [nested] = useState(nestedProp ? nestedProp : 0)
  const { link, links, name } = data

  return (
    <li css={sLinkChild(nested)}>
      {link ? (
        <Link to={link} className={`sublink${nested}`}>
          {name}
        </Link>
      ) : (
        <div
          className={`sublink${nested}`}
          onClick={data.action}
          onKeyPress={data.action}
          tabIndex={0}
          role="button">
          {name}
        </div>
      )}
      {links && (
        <div css={sArrow}>
          <Arrow />
        </div>
      )}

      {links && (
        <ul
          css={[
            sSublinksContainerLeft,
            links.length > 16 ? sGrid(links.length) : sFlex,
            id >= items / 2 && id > 6 && sSublinksContainerBottom,
            { zIndex: 10 + nested },
            id === 0 && { borderRadius: '1rem' },
            data.left && {
              left: 'auto',
              right: '100%',
              borderRadius: '1rem 0 1rem 1rem',
            },
          ]}
          className={`sublinks${nested}`}>
          {links.map((linkItem, id) => (
            <LinkChild
              key={id}
              data={linkItem}
              nested={nested + 1}
              items={links.length}
              id={id}
            />
          ))}
        </ul>
      )}
    </li>
  )
}

const sSublink = {
  transition: 'background-color 0.2s ease-out, color 0.2s ease-out',
  willChange: 'background-color',
  whiteSpace: 'nowrap',
  fontSize: '0.8125rem',
  padding: '0.75rem 2rem',
  width: '100%',
  cursor: 'pointer',
  [s.lg]: {
    fontSize: '0.875rem',
  },
}

const sLinkChild = (nested) => {
  const style = {
    listStyle: 'none',
    display: 'flex',
    position: 'relative',
    top: 0,
    left: 0,
    letterSpacing: '-0.025em',

    '.sublink0, .sublink1, .sublink2': { ...sSublink },
    // '&:hover': {
    //   color: colors[theme].mainLighter,
    //   svg: {
    //     fill: colors[theme].mainLighter,
    //   },
    // },
  }

  const hover0 = {
    '.sublink0': {
      backgroundColor: colors[theme].mainLighter.concat(alpha[16]),
      color: colors[theme].mainLighter,
    },
    '.sublinks0': { opacity: 1, pointerEvents: 'auto' },
  }

  const hover1 = {
    '.sublink1': {
      backgroundColor: colors[theme].mainLighter.concat(alpha[16]),
      color: colors[theme].mainLighter,
    },
    '.sublinks1': { opacity: 1, pointerEvents: 'auto' },
  }

  const hover2 = {
    '.sublink2': {
      backgroundColor: colors[theme].mainLighter.concat(alpha[16]),
      color: colors[theme].mainLighter,
    },
    '.sublinks2': { opacity: 1, pointerEvents: 'auto' },
  }

  switch (nested) {
    case 0: {
      return { ...style, '&:hover': { ...hover0 } }
    }
    case 1: {
      return { ...style, '&:hover': { ...hover1 } }
    }
    case 2: {
      return { ...style, '&:hover': { ...hover2 } }
    }
    default:
      return { ...style, '&:hover': { ...hover0 } }
  }
}

const sBackgroundMenu = {
  transition: 'opacity 0.3s ease-out',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: 128,
  backgroundColor: colors[theme].text,
  [s.xs]: {
    height: 96,
  },
}

const sLinkMain = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  top: 0,
  left: 0,
  fontWeight: 700,
  letterSpacing: '-0.05em',
  fontSize: '1rem',
  listStyle: 'none',

  '.link': {
    borderRadius: '1rem 1rem 0 0',
    transition:
      'background-color 0.2s ease-out, color 0.2s ease-out, box-shadow 0.2s ease-out',
    willChange: 'color, background-color, box-shadow',
    padding: '1.5rem 2rem',
    backgroundColor: colors[theme].text.concat(alpha[0]),
    textTransform: 'uppercase',
    fontSize: '0.75rem',
    letterSpacing: '0.05em',
    cursor: 'pointer',
    // wordWrap: 'nowrap',
    whiteSpace: 'nowrap',
    color: colors[theme].container,
  },

  '&:hover': {
    '.link': {
      backgroundColor: colors[theme].text,
      color: colors[theme].container,
      boxShadow: `4px 3px 16px ${colors[theme].text.concat(alpha[16])}`,
    },
    '.sublinks': {
      opacity: 1,
      pointerEvents: 'auto',
    },
  },
}

const sSublinksContainer = {
  transition: 'opacity 0.2s ease-out',
  opacity: 0,
  pointerEvents: 'none',
  position: 'absolute',
  top: '100%',
  left: 0,
  backgroundColor: colors[theme].text,
  color: colors[theme].container,
  borderRadius: '0 1rem 1rem 1rem',
  padding: '1rem 0',
  boxShadow: `4px 3px 16px ${colors[theme].text.concat(alpha[16])}`,
}

const sSublinksContainerLeft = {
  ...sSublinksContainer,
  // padding: 0,
  transition: 'opacity 0s',
  left: '100%',
  top: -16,
  borderRadius: '0 1rem 1rem 1rem',
}

const sSublinksContainerBottom = {
  top: 'auto',
  bottom: -16,
  borderRadius: '1rem 1rem 1rem 0',
}

const sUL = {
  display: 'flex',
  position: 'relative',
  top: 0,
  left: 0,
  zIndex: 1000,
}

const sMenu = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: 'calc(100% - 4rem)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: 'white',
  zIndex: 1000,
  padding: '0 2rem',
  height: '4rem',
  [s.xs]: {
    marginTop: '1rem',
  },
  [s.sm]: {
    marginTop: '2rem',
  },
  [s.md]: {
    width: 'calc(100% - 8rem)',
    padding: '0 4rem',
  },
  [s.lg]: {
    width: '100%',
    maxWidth: 1280 + 64,
    left: '50%',
    transform: 'translateX(-50%)',
  },
}

const sFlex = {
  display: 'flex',
  flexDirection: 'column',
}

const sFlexRow = {
  display: 'flex',
  flexDirection: 'row',
}

const sGrid = (items) => ({
  display: 'grid',
  gridTemplateColumns: items >= 30 ? '1fr 1fr 1fr' : '1fr 1fr',
})

const sBackground = (white) => ({
  pointerEvents: 'none',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: 3 * 4 + 'rem',
  [s.xs]: { height: 2.5 * 4 + 'rem' },
  [s.ss]: { height: 2 * 4 + 'rem' },
  [s.md]: {
    top: '-2rem',
    height: 4 * 4 + 'rem',
  },
  zIndex: 900,
  background: white
    ? `linear-gradient(to bottom, 
    ${colors[theme].container.concat(alpha[100])}, 
    ${colors[theme].container.concat(alpha[80])},
    ${colors[theme].container.concat(alpha[60])},
    ${colors[theme].container.concat(alpha[30])},
    ${colors[theme].container.concat(alpha[16])},
    ${colors[theme].container.concat(alpha[0])})`
    : `linear-gradient(to bottom, 
    ${colors[theme].text.concat(alpha[100])}, 
    ${colors[theme].text.concat(alpha[60])},
    ${colors[theme].text.concat(alpha[30])},
    ${colors[theme].text.concat(alpha[16])},
    ${colors[theme].text.concat(alpha[8])},
    ${colors[theme].text.concat(alpha[0])})`,
})

const sArrow = {
  width: '1rem',
  height: '1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: '50%',
  right: 0,
  transform: 'translateY(-50%)',
  marginRight: '0.5rem',
  svg: {
    width: '1rem',
    height: '1rem',
  },
  // color: 'inherit',
}

const sArrowRotated = {
  ...sArrow,
  transform: 'translateY(-50%) rotate(90deg)',
  color: 'currentColor',
}

// const sLogo = {
//   transition: 'color 0.2s ease-out',
//   display: 'flex',
//   position: 'relative',
//   top: 0,
//   left: 0,
//   zIndex: 1000,
//   width: 150,
//   svg: {
//     width: 'max-content',
//     display: 'flex',
//     height: 36,
//   },
// }

const sLogo = {
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  maxWidth: globals.menu.logo.maxWidth,
  maxHeight: globals.menu.logo.maxHeight,
  [s.sm_down]: {
    maxWidth: globals.menu.logo.mobile.maxWidth,
    maxHeight: globals.menu.logo.mobile.maxHeight,
  },
  img: {
    width: '100%',
    height: '90%',
    zIndex: 1000,
  },
}

const sDesktop = {
  [s.md_down]: { display: 'none' },
}

const sMobile = {
  [s.lg]: { display: 'none' },
}

export default MenuNew
